import Vue from "vue";
import config from "@/config";

const partnership = {};
const base = config.api_base + "/shop/plugin/partnership";

partnership.conf = (params) => Vue.axios.post(base + "/conf", params);
partnership.confEdit = (params) => Vue.axios.post(base + "/conf-edit", params);
partnership.upgradeGoodsAll = (params) => Vue.axios.post(base + "/upgrade-goods-all", params);
partnership.upgradeGoodsChoose = (params) => Vue.axios.post(base + "/upgrade-goods-choose", params);
partnership.userProfileSearch = (params) => Vue.axios.post(base + "/user-profile-search", params);
partnership.userProfileHands = (params) => Vue.axios.post(base + "/user-profile-hands", params);
partnership.userLevelNormal = (params) => Vue.axios.post(base + "/user-level-normal", params);
partnership.userAwardLog = (params) => Vue.axios.post(base + "/user-award-log", params);
partnership.shareholderSearch = (params) => Vue.axios.post(base + "/shareholder-search", params);
partnership.shareholderChoose = (params) => Vue.axios.post(base + "/shareholder-choose", params);
partnership.shareholderAwardLog = (params) => Vue.axios.post(base + "/shareholder-award-log", params);
partnership.shareholderGoodsAward = (params) => Vue.axios.post(base + "/shareholder-goods-award", params);
partnership.shareholderGoodsAwardEdit = (params) => Vue.axios.post(base + "/shareholder-goods-award-edit", params);
partnership.shareholderGoodsAwardEnable = (params) => Vue.axios.post(base + "/shareholder-goods-award-enable", params);
partnership.activeSearch = (params) => Vue.axios.post(base + "/active-search", params);
partnership.activeEdit = (params) => Vue.axios.post(base + "/active-edit", params);
partnership.activeDel = (params) => Vue.axios.post(base + "/active-del", params);
partnership.activeLogSearch = (params) => Vue.axios.post(base + "/active-log-search", params);
partnership.activeLogItemSearch = (params) => Vue.axios.post(base + "/active-log-item-search", params);

export default partnership;