import Vue from "vue";

import config from "@/config";

const user = {};
const base = config.api_base + "/shop/user"

user.search = (params)=>Vue.axios.post( base + "/search",params);
user.one = (params)=>Vue.axios.post( base + "/one",params);
user.setLevel = (params)=>Vue.axios.post( base + "/sel-level",params);
user.selInviteU = (params)=>Vue.axios.post( base + "/sel-invite-u",params);
user.handsRegister = (params)=>Vue.axios.post( base + "/hands-register",params);

user.cashSearch = (params)=>Vue.axios.post( base + "/cash-search",params);
user.cashSearchExport = (params)=>Vue.axios.post( base + "/cash-search-export",params);
user.cashPay = (params)=>Vue.axios.post( base + "/cash-pay",params);
user.cashCheck = (params)=>Vue.axios.post( base + "/cash-check",params);
user.cashFail = (params)=>Vue.axios.post( base + "/cash-fail",params);
user.cashAccountsByUid = (params)=>Vue.axios.post( base + "/cash-accounts-by-uid",params);

user.detail = (params)=>Vue.axios.post( base + "/detail",params);
user.balanceRecharge = (params)=>Vue.axios.post( base + "/balance-recharge",params);
user.inviteLinkSearch = (params)=>Vue.axios.post( base + "/invite-link-search",params);
user.balanceLog = (params)=>Vue.axios.post( base + "/balance-log",params);
user.awardLog = (params)=>Vue.axios.post( base + "/award-log",params);
user.team = (params)=>Vue.axios.post( base + "/team",params);
user.cashLog = (params)=>Vue.axios.post( base + "/cash-log",params);

user.cashJoinPay = (params)=>Vue.axios.post( base + "/cash-join-pay",params);
user.cashJoinPayQuery = (params)=>Vue.axios.post( base + "/cash-join-pay-query",params);

export default user