import Vue from "vue";
import config from "@/config";

const dealer = {};
const base = config.api_base + "/shop/plugin/dealer";

dealer.rule = (params) => Vue.axios.post(base + "/rule", params);
dealer.ruleEdit = (params) => Vue.axios.post(base + "/rule-edit", params);

dealer.poolSearch = (params) => Vue.axios.post(base + "/pool-search", params);
dealer.poolCloseLog = (params) => Vue.axios.post(base + "/pool-close-log", params);

dealer.userSearch = (params) => Vue.axios.post(base + "/user-search", params);
dealer.userSetLevel = (params) => Vue.axios.post(base + "/user-set-level", params);

export default dealer;