export default {
    path: "franchise-store",
    component: () => import("@/view/shop/plugin/franchise-store/index"),
    redirect: "/shop/plugin/franchise-store/manage",
    name: "shop-plugin-franchise-store",
    meta: {
        title: "加盟店"
    },
    children: [
        {
            path: "conf",
            name: "shop-plugin-franchise-store-conf",
            component: () => import("@/view/shop/plugin/franchise-store/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "manage",
            name: "shop-plugin-franchise-store-manage",
            component: () => import("@/view/shop/plugin/franchise-store/manage"),
            meta: {
                title: "用户列表"
            }
        },
        {
            path: "pool",
            name: "shop-plugin-franchise-store-pool",
            component: () => import("@/view/shop/plugin/franchise-store/pool"),
            meta: {
                title: "资金池"
            }
        },
    ]
}