export default {
    path: "operation-center",
    component: () => import("@/view/shop/plugin/operation-center/index"),
    redirect: "/shop/plugin/operation-center/manage",
    name: "shop-plugin-operation-center",
    meta: {
        title: "运营中心"
    },
    children: [
        {
            path: "conf",
            name: "shop-plugin-operation-center-conf",
            component: () => import("@/view/shop/plugin/operation-center/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "manage",
            name: "shop-plugin-operation-center-manage",
            component: () => import("@/view/shop/plugin/operation-center/manage"),
            meta: {
                title: "用户列表"
            }
        },
        {
            path: "pool",
            name: "shop-plugin-operation-center-pool",
            component: () => import("@/view/shop/plugin/operation-center/pool"),
            meta: {
                title: "资金池"
            }
        },
    ]
}