import Vue from "vue";
import config from "@/config";

const sys = {};
const base = config.api_base + "/shop/sys";

sys.bannerAll = (params)=>Vue.axios.post( base + "/banner-all",params);
sys.bannerEdit = (params)=>Vue.axios.post( base + "/banner-edit",params);
sys.bannerDel = (params)=>Vue.axios.post( base + "/banner-del",params);

sys.girdAll = (params)=>Vue.axios.post( base + "/gird-all",params);
sys.girdEdit = (params)=>Vue.axios.post( base + "/gird-edit",params);
sys.girdDel = (params)=>Vue.axios.post( base + "/gird-del",params);

sys.info = (params)=>Vue.axios.post( base + "/info",params);
sys.infoEdit = (params)=>Vue.axios.post( base + "/info-edit",params);

sys.poster = (params)=>Vue.axios.post( base + "/poster",params);
sys.posterEdit = (params)=>Vue.axios.post( base + "/poster-edit",params);
sys.posterClear = (params)=>Vue.axios.post( base + "/poster-clear",params);
sys.posterV2List = (params)=>Vue.axios.post( base + "/poster-v2-list",params);
sys.posterV2Edit = (params)=>Vue.axios.post( base + "/poster-v2-edit",params);
sys.posterV2One = (params)=>Vue.axios.post( base + "/poster-v2-one",params);
sys.posterV2Del = (params)=>Vue.axios.post( base + "/poster-v2-del",params);
sys.posterV2Clear = (params)=>Vue.axios.post( base + "/poster-v2-clear",params);



sys.wechatOfficial = (params)=>Vue.axios.post( base + "/wechat-official",params);
sys.wechatOfficialEdit = (params)=>Vue.axios.post( base + "/wechat-official-edit",params);

sys.wechatOfficialSubstitute = (params)=>Vue.axios.post( base + "/wechat-official-substitute",params);
sys.wechatOfficialSubstituteEdit = (params)=>Vue.axios.post( base + "/wechat-official-substitute-edit",params);

sys.wechatMiniUploadCode = (params)=>Vue.axios.post( base + "/wechat-mini-upload-code",params);
sys.wechatMini = (params)=>Vue.axios.post( base + "/wechat-mini",params);
sys.wechatMiniEdit = (params)=>Vue.axios.post( base + "/wechat-mini-edit",params);

sys.wechatPayment = (params)=>Vue.axios.post( base + "/wechat-payment",params);
sys.wechatPaymentEdit = (params)=>Vue.axios.post( base + "/wechat-payment-edit",params);

sys.aliPayment = (params)=>Vue.axios.post( base + "/ali-payment",params);
sys.aliPaymentEdit = (params)=>Vue.axios.post( base + "/ali-payment-edit",params);

sys.sms = (params)=>Vue.axios.post( base + "/sms",params);
sys.smsEdit = (params)=>Vue.axios.post( base + "/sms-edit",params);

sys.noticeOne = (params)=>Vue.axios.post( base + "/notice-one",params);
sys.noticeSearch = (params)=>Vue.axios.post( base + "/notice-search",params);
sys.noticeEdit = (params)=>Vue.axios.post( base + "/notice-edit",params);
sys.noticeDel = (params)=>Vue.axios.post( base + "/notice-del",params);

sys.rule = (params)=>Vue.axios.post( base + "/rule",params);
sys.ruleEdit = (params)=>Vue.axios.post( base + "/rule-edit",params);

sys.joinPay = (params)=>Vue.axios.post( base + "/join-pay",params);
sys.joinPayEdit = (params)=>Vue.axios.post( base + "/join-pay-edit",params);

sys.tabbar = (params)=>Vue.axios.post( base + "/tabbar",params);
sys.tabbarEdit = (params)=>Vue.axios.post( base + "/tabbar-edit",params);
sys.tabbarItem = (params)=>Vue.axios.post( base + "/tabbar-item",params);
sys.tabbarItemEdit = (params)=>Vue.axios.post( base + "/tabbar-item-edit",params);
sys.tabbarItemDel = (params)=>Vue.axios.post( base + "/tabbar-item-del",params);

sys.adPopup = (params)=>Vue.axios.post( base + "/ad-popup",params);
sys.adPopupEdit = (params)=>Vue.axios.post( base + "/ad-popup-edit",params);

export default sys;