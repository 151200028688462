import Vue from "vue";
import config from "@/config";

const blindAward = {};
const base = config.api_base + "/shop/plugin/blind-award";

blindAward.conf = (params) => Vue.axios.post(base + "/conf", params);
blindAward.confEdit = (params) => Vue.axios.post(base + "/conf-edit", params);
blindAward.goodsSearch = (params) => Vue.axios.post(base + "/goods-search", params);
blindAward.goodsConf = (params) => Vue.axios.post(base + "/goods-conf", params);
blindAward.goodsConfEdit = (params) => Vue.axios.post(base + "/goods-conf-edit", params);
blindAward.userSearch = (params) => Vue.axios.post(base + "/user-search", params);
blindAward.orderAwardDetail = (params) => Vue.axios.post(base + "/order-award-detail", params);

export default blindAward;