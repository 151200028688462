import Vue from "vue";
import config from "@/config";

const decorateLevel = {};
const base = config.api_base + "/shop/plugin/decorate-level";

decorateLevel.levelAll = (params) => Vue.axios.post(base + "/level-all", params);
decorateLevel.levelEdit = (params) => Vue.axios.post(base + "/level-edit", params);
decorateLevel.levelDel = (params) => Vue.axios.post(base + "/level-del", params);

decorateLevel.userSearch = (params) => Vue.axios.post(base + "/user-search", params);
decorateLevel.userAllCheck = (params) => Vue.axios.post(base + "/user-all-check", params);

export default decorateLevel;