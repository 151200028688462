export default {
    path: "blind-award",
    component: () => import("@/view/shop/plugin/blind-award/index"),
    redirect: "/shop/plugin/blind-award/user",
    name: "shopPluginBlindAward",
    meta: {
        title: "消费盲返"
    },
    children: [
        {
            path: "conf",
            name: "shopPluginBlindAwardConf",
            component: () => import("@/view/shop/plugin/blind-award/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "user",
            name: "shopPluginBlindAwardUser",
            component: () => import("@/view/shop/plugin/blind-award/user"),
            meta: {
                title: "盲返用户"
            }
        },
        {
            path: "goods",
            name: "shopPluginBlindAwardGoods",
            component: () => import("@/view/shop/plugin/blind-award/goods"),
            meta: {
                title: "专区商品"
            }
        },
        {
            path: "order-award-detail",
            name: "shopPluginBlindAwardOrderAwardDetail",
            component: () => import("@/view/shop/plugin/blind-award/orderAwardDetail"),
            meta: {
                title: "订单盲反详情"
            }
        },
    ]
}