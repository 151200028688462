import Vue from "vue";
import config from "@/config";

const privilegeCard = {};
const base = config.api_base + "/shop/plugin/privilege-card";

privilegeCard.dashboard = (params) => Vue.axios.post(base + "/dashboard", params);
privilegeCard.dashboardV2 = (params) => Vue.axios.post(base + "/dashboard-v2", params);

privilegeCard.conf = (params) => Vue.axios.post(base + "/conf", params);
privilegeCard.confEdit = (params) => Vue.axios.post(base + "/conf-edit", params);
privilegeCard.confBannerAll = (params) => Vue.axios.post(base + "/conf-banner-all", params);
privilegeCard.confBannerDel = (params) => Vue.axios.post(base + "/conf-banner-del", params);
privilegeCard.confBannerEdit = (params) => Vue.axios.post(base + "/conf-banner-edit", params);
privilegeCard.confRightsAll = (params) => Vue.axios.post(base + "/conf-rights-all", params);
privilegeCard.confRightsDel = (params) => Vue.axios.post(base + "/conf-rights-del", params);
privilegeCard.confRightsEdit = (params) => Vue.axios.post(base + "/conf-rights-edit", params);
privilegeCard.confNoticeSearch = (params) => Vue.axios.post(base + "/conf-notice-search", params);
privilegeCard.confNoticeOne = (params) => Vue.axios.post(base + "/conf-notice-one", params);
privilegeCard.confNoticeEdit = (params) => Vue.axios.post(base + "/conf-notice-edit", params);
privilegeCard.confNoticeDel = (params) => Vue.axios.post(base + "/conf-notice-del", params);

privilegeCard.merchantCateAll = (params) => Vue.axios.post(base + "/merchant-cate-all", params);
privilegeCard.merchantCateEdit = (params) => Vue.axios.post(base + "/merchant-cate-edit", params);
privilegeCard.merchantCateDel = (params) => Vue.axios.post(base + "/merchant-cate-del", params);

privilegeCard.merchantStatus = (params) => Vue.axios.post(base + "/merchant-status", params);
privilegeCard.merchantSearch = (params) => Vue.axios.post(base + "/merchant-search", params);
privilegeCard.merchantOne = (params) => Vue.axios.post(base + "/merchant-one", params);
privilegeCard.merchantEdit = (params) => Vue.axios.post(base + "/merchant-edit", params);
privilegeCard.merchantDel = (params) => Vue.axios.post(base + "/merchant-del", params);

privilegeCard.merchantClerkSearch = (params) => Vue.axios.post(base + "/merchant-clerk-search", params);
privilegeCard.merchantClerkEdit = (params) => Vue.axios.post(base + "/merchant-clerk-edit", params);
privilegeCard.merchantClerkDel = (params) => Vue.axios.post(base + "/merchant-clerk-del", params);

privilegeCard.merchantCouponSearch = (params) => Vue.axios.post(base + "/merchant-coupon-search", params);
privilegeCard.merchantCouponOne = (params) => Vue.axios.post(base + "/merchant-coupon-one", params);
privilegeCard.merchantCouponEdit = (params) => Vue.axios.post(base + "/merchant-coupon-edit", params);
privilegeCard.merchantCouponDel = (params) => Vue.axios.post(base + "/merchant-coupon-del", params);

privilegeCard.ruleMerchant = (params) => Vue.axios.post(base + "/rule-merchant", params);
privilegeCard.ruleMerchantEdit = (params) => Vue.axios.post(base + "/rule-merchant-edit", params);
privilegeCard.ruleAgent = (params) => Vue.axios.post(base + "/rule-agent", params);
privilegeCard.ruleAgentEdit = (params) => Vue.axios.post(base + "/rule-agent-edit", params);
privilegeCard.ruleCard = (params) => Vue.axios.post(base + "/rule-card", params);
privilegeCard.ruleCardEdit = (params) => Vue.axios.post(base + "/rule-card-edit", params);

privilegeCard.agentSearch = (params) => Vue.axios.post(base + "/agent-search", params);
privilegeCard.agentHandsApply = (params) => Vue.axios.post(base + "/agent-hands-apply", params);
privilegeCard.agentDel = (params) => Vue.axios.post(base + "/agent-del", params);

privilegeCard.cashierLog = (params) => Vue.axios.post(base + "/cashier-log", params);
privilegeCard.cashierLogExport = (params) => Vue.axios.post(base + "/cashier-log-export", params);

privilegeCard.memberSearch = (params) => Vue.axios.post(base + "/member-search", params);

privilegeCard.orderSearch = (params) => Vue.axios.post(base + "/order-search", params);
privilegeCard.orderAwardSearch = (params) => Vue.axios.post(base + "/order-award-search", params);


privilegeCard.posterList = (params) => Vue.axios.post(base + "/poster-list", params);
privilegeCard.posterEdit = (params) => Vue.axios.post(base + "/poster-edit", params);
privilegeCard.posterOne = (params) => Vue.axios.post(base + "/poster-one", params);
privilegeCard.posterDel = (params) => Vue.axios.post(base + "/poster-del", params);
privilegeCard.posterClear = (params) => Vue.axios.post(base + "/poster-clear", params);


privilegeCard.manageAll = (params) => Vue.axios.post(base + "/manage-all", params);
privilegeCard.manageAdd = (params) => Vue.axios.post(base + "/manage-add", params);
privilegeCard.manageDel = (params) => Vue.axios.post(base + "/manage-del", params);

privilegeCard.pageMineBanner = (params) => Vue.axios.post(base + "/page-mine-banner", params);
privilegeCard.pageMineBannerEdit = (params) => Vue.axios.post(base + "/page-mine-banner-edit", params);
privilegeCard.pageMineBannerDel = (params) => Vue.axios.post(base + "/page-mine-banner-del", params);

privilegeCard.wholesalerConf = (params) => Vue.axios.post(base + "/wholesaler/conf", params);
privilegeCard.wholesalerConfEdit = (params) => Vue.axios.post(base + "/wholesaler/conf-edit", params);
privilegeCard.wholesalerModeRule = (params) => Vue.axios.post(base + "/wholesaler/mode-rule", params);
privilegeCard.wholesalerModeRuleEdit = (params) => Vue.axios.post(base + "/wholesaler/mode-rule-edit", params);
privilegeCard.wholesalerActivationLog = (params) => Vue.axios.post(base + "/wholesaler/activation-log", params);
privilegeCard.wholesalerUserSearch = (params) => Vue.axios.post(base + "/wholesaler/user-search", params);
privilegeCard.wholesalerUserSearchExport = (params) => Vue.axios.post(base + "/wholesaler/user-search-export", params);
privilegeCard.wholesalerUserDetail = (params) => Vue.axios.post(base + "/wholesaler/user-detail", params);
privilegeCard.wholesalerUserTeam = (params) => Vue.axios.post(base + "/wholesaler/user-team", params);
privilegeCard.wholesalerUserInviteLinkSearch = (params) => Vue.axios.post(base + "/wholesaler/user-invite-link-search", params);
privilegeCard.wholesalerOrderSearch = (params) => Vue.axios.post(base + "/wholesaler/order-search", params);


privilegeCard.couponPosterConf = (params) => Vue.axios.post(base + "/coupon-poster-conf", params);
privilegeCard.couponPosterConfEdit = (params) => Vue.axios.post(base + "/coupon-poster-conf-edit", params);
privilegeCard.couponPosterClear = (params) => Vue.axios.post(base + "/coupon-poster-clear", params);

export default privilegeCard;