export default {
    path: "privilege-card",
    component: () => import("@/view/shop/plugin/privilege-card/index"),
    redirect: "/shop/plugin/privilege-card/dashboard",
    name: "shop-plugin-privilege-card",
    meta: {
        title: "免单卡"
    },
    children: [
        {
            path: "dashboard",
            name: "shop-plugin-privilege-card-dashboard",
            component: () => import("@/view/shop/plugin/privilege-card/dashboard"),
            meta: {
                title: "数据概览"
            }
        },
        {
            path: "rule",
            name: "shop-plugin-privilege-card-rule",
            component: () => import("@/view/shop/plugin/privilege-card/rule/index"),
            meta: {
                title: "基础配置"
            },
            children: [
                {
                    path: "card",
                    name: "shop-plugin-privilege-card-rule-card",
                    component: () => import("@/view/shop/plugin/privilege-card/rule/card"),
                    meta: {
                        title: "免单卡规则"
                    },
                },
                {
                    path: "merchant",
                    name: "shop-plugin-privilege-card-rule-merchant",
                    component: () => import("@/view/shop/plugin/privilege-card/rule/merchant"),
                    meta: {
                        title: "商家规则"
                    },
                },
                {
                    path: "agent",
                    name: "shop-plugin-privilege-card-rule-agent",
                    component: () => import("@/view/shop/plugin/privilege-card/rule/agent"),
                    meta: {
                        title: "代理规则"
                    },
                },
                {
                    path: "coupon-poster",
                    name: "shop-plugin-privilege-card-rule-coupon-poster",
                    component: () => import("@/view/shop/plugin/privilege-card/rule/couponPoster"),
                    meta: {
                        title: "卡券海报管理"
                    },
                },
                {
                    path: "poster",
                    name: "shop-plugin-privilege-card-rule-poster",
                    component: () => import("@/view/shop/plugin/privilege-card/rule/poster"),
                    meta: {
                        title: "海报管理"
                    },
                },
                {
                    path: "poster-edit",
                    name: "shop-plugin-privilege-card-rule-poster-edit",
                    component: () => import("@/view/shop/plugin/privilege-card/rule/poster-edit"),
                    meta: {
                        title: "海报编辑"
                    },
                },
                {
                    path: "manage",
                    name: "shop-plugin-privilege-card-rule-manage",
                    component: () => import("@/view/shop/plugin/privilege-card/rule/manage"),
                    meta: {
                        title: "平台管理员"
                    },
                },
            ]
        },
        {
            path: "conf",
            name: "shop-plugin-privilege-card-conf",
            component: () => import("@/view/shop/plugin/privilege-card/conf/index"),
            redirect: "/shop/plugin/privilege-card/conf/edit",
            meta: {
                title: "页面设置"
            },
            children: [
                {
                    path: "edit",
                    name: "shop-plugin-privilege-card-conf-edit",
                    component: () => import("@/view/shop/plugin/privilege-card/conf/edit"),
                    meta: {
                        title: "免单卡页面"
                    },
                },
                {
                    path: "banner",
                    name: "shop-plugin-privilege-card-conf-banner",
                    component: () => import("@/view/shop/plugin/privilege-card/conf/banner"),
                    meta: {
                        title: "免单卡轮播"
                    },
                },
                {
                    path: "rights",
                    name: "shop-plugin-privilege-card-conf-rights",
                    component: () => import("@/view/shop/plugin/privilege-card/conf/rights"),
                    meta: {
                        title: "免单卡权益"
                    },
                },
                {
                    path: "notice",
                    name: "shop-plugin-privilege-card-conf-notice",
                    component: () => import("@/view/shop/plugin/privilege-card/conf/notice"),
                    meta: {
                        title: "免单卡公告"
                    },
                },
                {
                    path: "notice-edit",
                    name: "shop-plugin-privilege-card-conf-notice-edit",
                    component: () => import("@/view/shop/plugin/privilege-card/conf/notice-edit"),
                    meta: {
                        title: "免单卡公告编辑"
                    },
                },
                {
                    path: "page-mine-banner",
                    name: "shop-plugin-privilege-card-conf-page-mine-banner",
                    component: () => import("@/view/shop/plugin/privilege-card/conf/page-mine-banner"),
                    meta: {
                        title: "个人中心轮播图"
                    },
                }
            ]
        },
        {
            path: "merchant-cate",
            name: "shop-plugin-privilege-card-merchant-cate",
            component: () => import("@/view/shop/plugin/privilege-card/merchant-cate"),
            meta: {
                title: "商家分类"
            }
        },
        {
            path: "merchant",
            name: "shop-plugin-privilege-card-merchant",
            component: () => import("@/view/shop/plugin/privilege-card/merchant/index"),
            redirect: "/shop/plugin/privilege-card/merchant/manage",
            meta: {
                title: "商家管理"
            },
            children:[
                {
                    path: "stop",
                    name: "shop-plugin-privilege-card-merchant-stoped",
                    component: () => import("@/view/shop/plugin/privilege-card/merchant/stop"),
                    meta: {
                        title: "已停用"
                    }
                },
                {
                    path: "manage",
                    name: "shop-plugin-privilege-card-merchant-manage",
                    component: () => import("@/view/shop/plugin/privilege-card/merchant/manage"),
                    meta: {
                        title: "入驻中"
                    }
                },
                {
                    path: "edit",
                    name: "shop-plugin-privilege-card-merchant-edit",
                    component: () => import("@/view/shop/plugin/privilege-card/merchant/edit"),
                    meta: {
                        title: "商家编辑"
                    }
                },
                {
                    path: "clerk",
                    name: "shop-plugin-privilege-card-merchant-clerk",
                    component: () => import("@/view/shop/plugin/privilege-card/merchant/clerk"),
                    meta: {
                        title: "员工管理"
                    }
                },
                {
                    path: "coupon",
                    name: "shop-plugin-privilege-card-merchant-coupon",
                    component: () => import("@/view/shop/plugin/privilege-card/merchant/coupon"),
                    meta: {
                        title: "卡券管理"
                    }
                },
                {
                    path: "coupon-edit",
                    name: "shop-plugin-privilege-card-merchant-coupon-edit",
                    component: () => import("@/view/shop/plugin/privilege-card/merchant/coupon-edit"),
                    meta: {
                        title: "卡券编辑"
                    }
                },
            ]
        },
        {
            path: "coupon",
            name: "shop-plugin-privilege-card-coupon",
            component: () => import("@/view/shop/plugin/privilege-card/coupon/index"),
            redirect: "/shop/plugin/privilege-card/coupon/manage",
            meta: {
                title: "卡券管理"
            },
            children:[
                {
                    path: "manage",
                    name: "shop-plugin-privilege-card-coupon-manage",
                    component: () => import("@/view/shop/plugin/privilege-card/coupon/manage"),
                    meta: {
                        title: "卡券列表"
                    }
                },
            ]
        },
        {
            path: "order",
            name: "shop-plugin-privilege-card-order",
            component: () => import("@/view/shop/plugin/privilege-card/order"),
            meta: {
                title: "订单管理"
            }
        },
        {
            path: "user",
            name: "shop-plugin-privilege-card-user",
            component: () => import("@/view/shop/plugin/privilege-card/user/index"),
            redirect: "/shop/plugin/privilege-card/user/manage",
            meta: {
                title: "用户管理"
            },
            children:[
                {
                    path: "manage",
                    name: "shop-plugin-privilege-card-user-manage",
                    component: () => import("@/view/shop/plugin/privilege-card/user/manage"),
                    meta: {
                        title: "用户列表"
                    }
                },
            ]
        },
        {
            path: "agent",
            name: "shop-plugin-privilege-card-agent",
            component: () => import("@/view/shop/plugin/privilege-card/agent/index"),
            redirect: "/shop/plugin/privilege-card/agent/manage",
            meta: {
                title: "代理商"
            },
            children:[
                {
                    path: "manage",
                    name: "shop-plugin-privilege-card-agent-manage",
                    component: () => import("@/view/shop/plugin/privilege-card/agent/manage"),
                    meta: {
                        title: "代理列表"
                    }
                },
            ]
        },
        {
            path: "area-agent",
            name: "shop-plugin-privilege-card-area-agent",
            component: () => import("@/view/shop/plugin/privilege-card/area-agent/index"),
            redirect: "/shop/plugin/privilege-card/area-agent/manage",
            meta: {
                title: "区域代理"
            },
            children:[
                {
                    path: "manage",
                    name: "shop-plugin-privilege-card-area-agent-manage",
                    component: () => import("@/view/shop/plugin/privilege-card/area-agent/manage"),
                    meta: {
                        title: "区域代理列表"
                    }
                },
            ]
        },
        {
            path: "finance",
            name: "shop-plugin-privilege-card-finance",
            component: () => import("@/view/shop/plugin/privilege-card/finance/index"),
            redirect: "/shop/plugin/privilege-card/finance/manage",
            meta: {
                title: "财务管理"
            },
            children:[
                {
                    path: "manage",
                    name: "shop-plugin-privilege-card-finance-manage",
                    component: () => import("@/view/shop/plugin/privilege-card/finance/manage"),
                    meta: {
                        title: "待打款列表"
                    }
                },
            ]
        },
        {
            path: "wholesaler",
            name: "shop-plugin-privilege-card-wholesaler",
            component: () => import("@/view/shop/plugin/privilege-card/wholesaler/index"),
            redirect: "/shop/plugin/privilege-card/wholesaler/user",
            meta: {
                title: "批发商管理"
            },
            children:[
                {
                    path: "conf",
                    name: "shop-plugin-privilege-card-wholesaler-conf",
                    component: () => import("@/view/shop/plugin/privilege-card/wholesaler/conf"),
                    meta: {
                        title: "基础配置"
                    }
                },
                {
                    path: "mode-rule",
                    name: "shop-plugin-privilege-card-wholesaler-mode-rule",
                    component: () => import("@/view/shop/plugin/privilege-card/wholesaler/mode-rule"),
                    meta: {
                        title: "模式规则"
                    }
                },
                {
                    path: "user",
                    name: "shop-plugin-privilege-card-wholesaler-mode-user",
                    component: () => import("@/view/shop/plugin/privilege-card/wholesaler/user"),
                    meta: {
                        title: "批发商管理"
                    }
                },
                {
                    path: "order",
                    name: "shop-plugin-privilege-card-wholesaler-mode-order",
                    component: () => import("@/view/shop/plugin/privilege-card/wholesaler/order"),
                    meta: {
                        title: "激活卡订单"
                    }
                },
            ]
        },

    ]
}