export default {
    path: "area-agent",
    component: () => import("@/view/shop/plugin/area-agent/index"),
    redirect: "/shop/plugin/area-agent/manage",
    name: "shopPluginAreaAgent",
    meta: {
        title: "区域代理"
    },
    children: [
        {
            path: "conf",
            name: "shopPluginAreaAgentConf",
            component: () => import("@/view/shop/plugin/area-agent/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "manage",
            name: "shopPluginAreaAgentManage",
            component: () => import("@/view/shop/plugin/area-agent/manage"),
            meta: {
                title: "代理管理"
            }
        },
        {
            path: "goods",
            name: "shopPluginAreaAgentGoods",
            component: () => import("@/view/shop/plugin/area-agent/goods"),
            meta: {
                title: "代理管理"
            }
        },
    ]
}