import Vue from "vue";
import config from "@/config";

const capital = {};
const base = config.api_base + "/shop/plugin/capital";

capital.confFetch = (params) => Vue.axios.post(base + "/conf-fetch", params);
capital.confEdit = (params) => Vue.axios.post(base + "/conf-edit", params);

capital.userSearch = (params) => Vue.axios.post(base + "/user-search", params);
capital.userAdd = (params) => Vue.axios.post(base + "/user-add", params);
capital.userDel = (params) => Vue.axios.post(base + "/user-del", params);
capital.userAllCheck = (params) => Vue.axios.post(base + "/user-all-check", params);

capital.pool = (params) => Vue.axios.post(base + "/pool", params);
capital.poolGetLog = (params) => Vue.axios.post(base + "/pool-get-log", params);
capital.poolOutLog = (params) => Vue.axios.post(base + "/pool-out-log", params);

export default capital;