import Vue from "vue";
import config from "@/config";

const scanGoods = {};
const base = config.api_base + "/shop/plugin/scan-goods";

scanGoods.clerkAll = (params) => Vue.axios.post(base + "/clerk-all", params);
scanGoods.clerkSet = (params) => Vue.axios.post(base + "/clerk-set", params);

scanGoods.clerkV2All = (params) => Vue.axios.post(base + "/clerk-v2-all", params);
scanGoods.clerkV2Set = (params) => Vue.axios.post(base + "/clerk-v2-set", params);

scanGoods.storeDel = (params) => Vue.axios.post(base + "/store-del", params);
scanGoods.storeAll = (params) => Vue.axios.post(base + "/store-all", params);
scanGoods.storeEdit = (params) => Vue.axios.post(base + "/store-edit", params);

scanGoods.goodsDel = (params) => Vue.axios.post(base + "/goods-del", params);
scanGoods.goodsSearch = (params) => Vue.axios.post(base + "/goods-search", params);
scanGoods.goodsEdit = (params) => Vue.axios.post(base + "/goods-edit", params);
scanGoods.goodsV2Del = (params) => Vue.axios.post(base + "/goods-v2-del", params);
scanGoods.goodsV2Search = (params) => Vue.axios.post(base + "/goods-v2-search", params);
scanGoods.goodsV2Edit = (params) => Vue.axios.post(base + "/goods-v2-edit", params);
scanGoods.goodsV2id2themeId = (params) => Vue.axios.post(base + "/goods-v2-id2theme-id", params);

scanGoods.orderSearch = (params) => Vue.axios.post(base + "/order-search", params);

scanGoods.cashierLog = (params) => Vue.axios.post(base + "/cashier-log", params);

export default scanGoods;