export default {
    path: "scan-goods",
    component: () => import("@/view/shop/plugin/scan-goods/index"),
    redirect: "/shop/plugin/scan-goods/order",
    name: "shopPluginScanGoods",
    meta: {
        title: "核销商品"
    },
    children: [
        {
            path: "goods",
            name: "shopPluginScanGoodsGoods",
            component: () => import("@/view/shop/plugin/scan-goods/goods"),
            meta: {
                title: "商品管理"
            }
        },
        {
            path: "order",
            name: "shopPluginScanGoodsOrder",
            component: () => import("@/view/shop/plugin/scan-goods/order"),
            meta: {
                title: "订单记录"
            }
        },
        {
            path: "store",
            name: "shopPluginScanGoodsStore",
            component: () => import("@/view/shop/plugin/scan-goods/store"),
            meta: {
                title: "核销门店"
            }
        },
        {
            path: "cashier-log",
            name: "shopPluginScanGoodsCashierLog",
            component: () => import("@/view/shop/plugin/scan-goods/cashier-log"),
            meta: {
                title: "核销记录"
            }
        },
        {
            path: "clerk",
            name: "shopPluginScanGoodsClerk",
            component: () => import("@/view/shop/plugin/scan-goods/clerk"),
            meta: {
                title: "核销员"
            }
        },
    ]
}