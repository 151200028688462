import goods from "@/api/shop/goods";
import order from "@/api/shop/order";
import config from "@/config";
import Vue from "vue";
import sys from "@/api/shop/sys";
import plugin from "@/api/shop/plugin";

export default {
    desc:(params)=>Vue.axios.post( config.api_base+"/shop/desc",params),
    goods,
    order,
    sys,
    plugin,
}