export default {
    path: "user",
    component: () => import("@/view/shop/user/index"),
    redirect: "/shop/user/manage",
    name: "user",
    meta: {
        title: "用户"
    },
    children: [
        {
            path: "manage",
            name: "shopUserManage",
            component: () => import("@/view/shop/user/manage"),
            meta: {
                title: "用户管理"
            }
        }
    ]
}