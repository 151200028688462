export default {
    path: "knowledge",
    component: () => import("@/view/shop/plugin/knowledge/index"),
    redirect: "/shop/plugin/knowledge/course",
    name: "shopPluginKnowledge",
    meta: {
        title: "知识付费"
    },
    children: [
        {
            path: "conf",
            name: "shopPluginKnowledgeConf",
            component: () => import("@/view/shop/plugin/knowledge/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "teacher",
            name: "shopPluginKnowledgeTeacher",
            component: () => import("@/view/shop/plugin/knowledge/teacher/index"),
            redirect: "/shop/plugin/knowledge/teacher/list",
            meta: {
                title: "讲师管理"
            },
            children: [
                {
                    path: "list",
                    name: "shopPluginKnowledgeTeacherList",
                    component: () => import("@/view/shop/plugin/knowledge/teacher/list"),
                    meta: {
                        title: "讲师列表"
                    },
                },
                {
                    path: "edit",
                    name: "shopPluginKnowledgeTeacherEdit",
                    component: () => import("@/view/shop/plugin/knowledge/teacher/edit"),
                    meta: {
                        title: "编辑讲师信息"
                    },
                },
                {
                    path: "award",
                    name: "shopPluginKnowledgeTeacherAward",
                    component: () => import("@/view/shop/plugin/knowledge/teacher/award"),
                    meta: {
                        title: "讲师佣金明细"
                    },
                },
            ]
        },
        {
            path: "cate",
            name: "shopPluginKnowledgeCate",
            component: () => import("@/view/shop/plugin/knowledge/cate"),
            meta: {
                title: "课程分类"
            }
        },
        {
            path: "course",
            name: "shopPluginKnowledgeCourse",
            component: () => import("@/view/shop/plugin/knowledge/course/index"),
            meta: {
                title: "课程管理"
            },
            redirect: "/shop/plugin/knowledge/course/list",
            children: [
                {
                    path: "list",
                    name: "shopPluginKnowledgeCourseList",
                    component: () => import("@/view/shop/plugin/knowledge/course/list"),
                    meta: {
                        title: "课程列表"
                    },
                },
                {
                    path: "edit",
                    name: "shopPluginKnowledgeCourseEdit",
                    component: () => import("@/view/shop/plugin/knowledge/course/edit"),
                    meta: {
                        title: "编辑课程信息"
                    },
                },
                {
                    path: "apply",
                    name: "shopPluginKnowledgeCourseApply",
                    component: () => import("@/view/shop/plugin/knowledge/course/apply"),
                    meta: {
                        title: "报名列表"
                    },
                },
                {
                    path: "column",
                    name: "shopPluginKnowledgeCourseColumn",
                    component: () => import("@/view/shop/plugin/knowledge/course/column"),
                    meta: {
                        title: "章节管理"
                    },
                },
                {
                    path: "column-edit",
                    name: "shopPluginKnowledgeCourseColumnEdit",
                    component: () => import("@/view/shop/plugin/knowledge/course/column-edit"),
                    meta: {
                        title: "章节编辑"
                    },
                },
            ]
        }
    ]
}