import Vue from "vue";
import config from "@/config";

const areaAgent = {};
const base = config.api_base + "/shop/plugin/area-agent";

areaAgent.rule = (params) => Vue.axios.post(base + "/rule", params);
areaAgent.ruleEdit = (params) => Vue.axios.post(base + "/rule-edit", params);

areaAgent.userSearch = (params) => Vue.axios.post(base + "/user-search", params);
areaAgent.userSetLevel = (params) => Vue.axios.post(base + "/user-set-level", params);
areaAgent.userSetAreaCode = (params) => Vue.axios.post(base + "/user-set-area-code", params);

areaAgent.upGoodsRemove = (params) => Vue.axios.post(base + "/up-goods-remove", params);
areaAgent.upGoodsSet = (params) => Vue.axios.post(base + "/up-goods-set", params);
areaAgent.upGoodsSearch = (params) => Vue.axios.post(base + "/up-goods-search", params);

export default areaAgent;