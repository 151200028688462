import Vue from "vue";
import config from "@/config";

const cmo = {};
const base = config.api_base + "/shop/plugin/cmo";

cmo.rule = (params) => Vue.axios.post(base + "/rule", params);
cmo.ruleEdit = (params) => Vue.axios.post(base + "/rule-edit", params);

cmo.userSearch = (params) => Vue.axios.post(base + "/user-search", params);
cmo.userSetCmo = (params) => Vue.axios.post(base + "/user-set-cmo", params);

cmo.apply = (params)=>Vue.axios.post( base + "/apply",params);
cmo.applyDose = (params)=>Vue.axios.post( base + "/apply-dose",params);

export default cmo;