import Vue from "vue";
import config from "@/config";

const teamManage = {};
const base = config.api_base + "/shop/plugin/team-manage";

teamManage.rule = (params) => Vue.axios.post(base + "/rule", params);
teamManage.ruleEdit = (params) => Vue.axios.post(base + "/rule-edit", params);

teamManage.levelAll = (params) => Vue.axios.post(base + "/level-all", params);
teamManage.levelEdit = (params) => Vue.axios.post(base + "/level-edit", params);
teamManage.levelDel = (params) => Vue.axios.post(base + "/level-del", params);

teamManage.userSearch = (params) => Vue.axios.post(base + "/user-search", params);

export default teamManage;