export default {
    path: "partnership",
    component: () => import("@/view/shop/plugin/partnership/index"),
    redirect: "/shop/plugin/partnership/conf",
    name: "shopPluginPartnership",
    meta: {
        title: "新合伙人"
    },
    children: [
        {
            path: "conf",
            name: "shopPluginPartnershipConf",
            component: () => import("@/view/shop/plugin/partnership/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "upgrade-goods",
            name: "shopPluginPartnershipUpgradeGoods",
            component: () => import("@/view/shop/plugin/partnership/upgrade-goods.vue"),
            meta: {
                title: "升级商品"
            }
        },
        {
            path: "user",
            name: "shopPluginPartnershipUser",
            component: () => import("@/view/shop/plugin/partnership/user"),
            meta: {
                title: "用户列表"
            }
        },
        {
            path: "shareholder",
            name: "shopPluginPartnershipShareholder",
            component: () => import("@/view/shop/plugin/partnership/shareholder"),
            meta: {
                title: "股东列表"
            }
        },
        {
            path: "active",
            name: "shopPluginPartnershipActive",
            component: () => import("@/view/shop/plugin/partnership/active"),
            meta: {
                title: "限期活动"
            }
        },{
            path: "active-log",
            name: "shopPluginPartnershipActiveLog",
            component: () => import("@/view/shop/plugin/partnership/active-log"),
            meta: {
                title: "限期活动详情"
            }
        },
    ]
}