export default {
    path: "article",
    component: () => import("@/view/shop/article/index"),
    redirect: "/shop/article/page",
    name: "shop-article",
    meta: {
        title: "文章"
    },
    children: [
        {
            path: "cate",
            name: "shop-article-cate",
            component: () => import("@/view/shop/article/cate"),
            meta: {
                title: "分类管理"
            }
        },
        {
            path: "page",
            name: "shop-article-page",
            component: () => import("@/view/shop/article/page"),
            meta: {
                title: "文章管理"
            }
        },
        {
            path: "edit",
            name: "shop-article-edit",
            component: () => import("@/view/shop/article/edit"),
            meta: {
                title: "文章编辑"
            }
        }
    ]
}