export default {
    path: "capital",
    component: () => import("@/view/shop/plugin/capital/index"),
    redirect: "/shop/plugin/capital/manage",
    name: "shop-plugin-capital",
    meta: {
        title: "团长"
    },
    children: [
        {
            path: "conf",
            name: "shop-plugin-capital-conf",
            component: () => import("@/view/shop/plugin/capital/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "manage",
            name: "shop-plugin-capital-manage",
            component: () => import("@/view/shop/plugin/capital/manage"),
            meta: {
                title: "用户列表"
            }
        },
        {
            path: "pool",
            name: "shop-plugin-capital-pool",
            component: () => import("@/view/shop/plugin/capital/pool"),
            meta: {
                title: "资金池"
            }
        },
    ]
}