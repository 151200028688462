export default {
    path: "decorate-level",
    component: () => import("@/view/shop/plugin/decorate-level/index"),
    redirect: "/shop/plugin/decorate-level/manage",
    name: "shop-plugin-decorate-level",
    meta: {
        title: "装饰等级"
    },
    children: [
        {
            path: "list",
            name: "shop-plugin-decorate-level-list",
            component: () => import("@/view/shop/plugin/decorate-level/list"),
            meta: {
                title: "等级设置"
            }
        },
        {
            path: "manage",
            name: "shopPluginTeamManageManage",
            component: () => import("@/view/shop/plugin/decorate-level/manage"),
            meta: {
                title: "用户列表"
            }
        }
    ]
}