export default {
    path: "cmo",
    component: () => import("@/view/shop/plugin/cmo/index"),
    redirect: "/shop/plugin/cmo/manage",
    name: "shopPluginCmo",
    meta: {
        title: "总监分红"
    },
    children: [
        {
            path: "conf",
            name: "shopPluginCmoConf",
            component: () => import("@/view/shop/plugin/cmo/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "manage",
            name: "shopPluginCmoManage",
            component: () => import("@/view/shop/plugin/cmo/manage"),
            meta: {
                title: "总监管理"
            }
        },
    ]
}