export default {
    path: "shareholder",
    component: () => import("@/view/shop/plugin/shareholder/index"),
    redirect: "/shop/plugin/shareholder/manage",
    name: "shopPluginShareholder",
    meta: {
        title: "股东分红"
    },
    children: [
        {
            path: "conf",
            name: "shopPluginShareholderConf",
            component: () => import("@/view/shop/plugin/shareholder/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "manage",
            name: "shopPluginShareholderManage",
            component: () => import("@/view/shop/plugin/shareholder/manage"),
            meta: {
                title: "股东管理"
            }
        },
        {
            path: "log",
            name: "shopPluginShareHolderLog",
            component: () => import("@/view/shop/plugin/shareholder/log"),
            meta: {
                title: "分红日志"
            }
        },
    ]
}