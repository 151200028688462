export default {
    path: "points-mall",
    component: () => import("@/view/shop/plugin/points-mall/index"),
    redirect: "/shop/plugin/points-mall/goods",
    name: "shop-plugin-points-mall",
    meta: {
        title: "积分商城"
    },
    children: [
        {
            path: "conf",
            name: "shop-plugin-points-mall-conf",
            component: () => import("@/view/shop/plugin/points-mall/conf"),
            meta: {
                title: "规则配置"
            }
        },
        {
            path: "goods",
            name: "shop-plugin-points-mall-goods",
            component: () => import("@/view/shop/plugin/points-mall/goods/index"),
            meta: {
                title: "商品管理"
            },
            redirect:"/shop/plugin/points-mall/goods/list",
            children:[
                {
                    path: "list",
                    name: "shop-plugin-points-mall-goods-list",
                    component: () => import("@/view/shop/plugin/points-mall/goods/list"),
                    meta: {
                        title: "商品列表"
                    },
                },
                {
                    path: "edit",
                    name: "shop-plugin-points-mall-goods-edit",
                    component: () => import("@/view/shop/plugin/points-mall/goods/edit"),
                    meta: {
                        title: "商品编辑"
                    },
                }
            ]
        },
        {
            path: "order",
            name: "shop-plugin-points-mall-order",
            component: () => import("@/view/shop/plugin/points-mall/order"),
            meta: {
                title: "兑换订单"
            }
        },
        {
            path: "user",
            name: "shop-plugin-points-mall-user",
            component: () => import("@/view/shop/plugin/points-mall/user"),
            meta: {
                title: "用户管理"
            }
        },
    ]
}