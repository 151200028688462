import Vue from "vue";
import config from "@/config";

const operationCenter = {};
const base = config.api_base + "/shop/plugin/operation-center";

operationCenter.confFetch = (params) => Vue.axios.post(base + "/conf-fetch", params);
operationCenter.confEdit = (params) => Vue.axios.post(base + "/conf-edit", params);

operationCenter.userSearch = (params) => Vue.axios.post(base + "/user-search", params);
operationCenter.userAdd = (params) => Vue.axios.post(base + "/user-add", params);
operationCenter.userDel = (params) => Vue.axios.post(base + "/user-del", params);

operationCenter.pool = (params) => Vue.axios.post(base + "/pool", params);
operationCenter.poolGetLog = (params) => Vue.axios.post(base + "/pool-get-log", params);
operationCenter.poolOutLog = (params) => Vue.axios.post(base + "/pool-out-log", params);

export default operationCenter;