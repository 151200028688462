import VueRouter from 'vue-router';
import shopRouter from "@/router/shop";
import accountRouter from "@/router/account";
import founderRouter from "@/router/founder";
import {routerSafe} from "@/common/util";
const routes = [
    {
        path: "/",
        redirect:"/account/info",
    },
    ...shopRouter,
    ...accountRouter,
    ...founderRouter,
    {
        path:"*",
        redirect:"/account/info"
    },
]
const router = new VueRouter({
    history: "hash",
    routes: routes,
});

routerSafe(router);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default router


