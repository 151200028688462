export default {
    path: "dealer",
    component: () => import("@/view/shop/plugin/dealer/index"),
    redirect: "/shop/plugin/dealer/manage",
    name: "shopPluginDealer",
    meta: {
        title: "经销商分红"
    },
    children: [
        {
            path: "conf",
            name: "shopPluginDealerConf",
            component: () => import("@/view/shop/plugin/dealer/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "manage",
            name: "shopPluginDealerManage",
            component: () => import("@/view/shop/plugin/dealer/manage"),
            meta: {
                title: "经销商管理"
            }
        },
        {
            path: "log",
            name: "shopPluginAreaAgentLog",
            component: () => import("@/view/shop/plugin/dealer/log"),
            meta: {
                title: "分红日志"
            }
        },
    ]
}