import Vue from "vue";
import config from "@/config";

const knowledge = {};
const base = config.api_base + "/shop/plugin/knowledge";

knowledge.rule = (params) => Vue.axios.post(base + "/rule", params);
knowledge.ruleEdit = (params) => Vue.axios.post(base + "/rule-edit", params);

knowledge.cate = (params) => Vue.axios.post(base + "/cate", params);
knowledge.cateEdit = (params) => Vue.axios.post(base + "/cate-edit", params);
knowledge.cateDel = (params) => Vue.axios.post(base + "/cate-del", params);

knowledge.teacher = (params) => Vue.axios.post(base + "/teacher", params);
knowledge.teacherOne = (params) => Vue.axios.post(base + "/teacher-one", params);
knowledge.teacherEdit = (params) => Vue.axios.post(base + "/teacher-edit", params);
knowledge.teacherDel = (params) => Vue.axios.post(base + "/teacher-del", params);
knowledge.teacherAwardList = (params) => Vue.axios.post(base + "/teacher-award-list", params);

knowledge.course = (params) => Vue.axios.post(base + "/course", params);
knowledge.courseApplyList = (params) => Vue.axios.post(base + "/course-apply-list", params);
knowledge.courseOne = (params) => Vue.axios.post(base + "/course-one", params);
knowledge.courseEdit = (params) => Vue.axios.post(base + "/course-edit", params);
knowledge.courseDel = (params) => Vue.axios.post(base + "/course-del", params);
knowledge.courseColumn = (params) => Vue.axios.post(base + "/course-column", params);
knowledge.courseColumnOne = (params) => Vue.axios.post(base + "/course-column-one", params);
knowledge.courseColumnEdit = (params) => Vue.axios.post(base + "/course-column-edit", params);
knowledge.courseColumnDel = (params) => Vue.axios.post(base + "/course-column-del", params);


export default knowledge;