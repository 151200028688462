import Vue from "vue";
import config from "@/config";
const manage = {};

const base = config.api_base + "/shop/plugin/manage";


manage.fetchConf = (params)=>Vue.axios.post( base + "/fetch-conf",params);
manage.added = (params)=>Vue.axios.post( base + "/added",params);
manage.addedChange = (params)=>Vue.axios.post( base + "/added-change",params);

export default manage;