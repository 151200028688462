import Vue from "vue";
import config from "@/config";

const franchiseStore = {};
const base = config.api_base + "/shop/plugin/franchise-store";

franchiseStore.confFetch = (params) => Vue.axios.post(base + "/conf-fetch", params);
franchiseStore.confEdit = (params) => Vue.axios.post(base + "/conf-edit", params);

franchiseStore.userSearch = (params) => Vue.axios.post(base + "/user-search", params);
franchiseStore.userAdd = (params) => Vue.axios.post(base + "/user-add", params);
franchiseStore.userDel = (params) => Vue.axios.post(base + "/user-del", params);

franchiseStore.pool = (params) => Vue.axios.post(base + "/pool", params);
franchiseStore.poolGetLog = (params) => Vue.axios.post(base + "/pool-get-log", params);
franchiseStore.poolOutLog = (params) => Vue.axios.post(base + "/pool-out-log", params);

export default franchiseStore;