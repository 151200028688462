export default {
    path: "system",
    component: () => import("@/view/shop/system/index"),
    redirect: "/shop/system/sys-notice",
    name: "shopSystem",
    meta: {
        title: "系统"
    },
    children: [
        {
            path: "entry",
            name: "shopSystemEntry",
            component: () => import("@/view/shop/system/entry"),
            meta: {
                title: "入口链接"
            }
        },
        {
            path: "info",
            name: "shopSystemInfo",
            component: () => import("@/view/shop/system/info"),
            meta: {
                title: "系统配置"
            }
        },
        {
            path: "rule",
            name: "shopSystemRule",
            component: () => import("@/view/shop/system/rule"),
            meta: {
                title: "基础规则"
            }
        },
        {
            path: "poster-v2",
            name: "shop-system-rule-poster-v2",
            component: () => import("@/view/shop/system/poster-v2"),
            meta: {
                title: "海报管理"
            },
        },
        {
            path: "poster-v2-edit",
            name: "shop-system-rule-poster-v2-edit",
            component: () => import("@/view/shop/system/poster-v2-edit"),
            meta: {
                title: "海报编辑"
            },
        },
        {
            path: "poster",
            name: "shopSystemPoster",
            component: () => import("@/view/shop/system/poster"),
            meta: {
                title: "海报配置"
            }
        },
        {
            path: "banner",
            name: "shopSystemBanner",
            component: () => import("@/view/shop/system/page/banner"),
            meta: {
                title: "首页轮播图"
            }
        },
        {
            path: "tabbar",
            name: "shopSystemTabbar",
            component: () => import("@/view/shop/system/page/tabbar"),
            meta: {
                title: "自定义菜单"
            }
        },
        {
            path: "gird",
            name: "shpSystemGird",
            component: () => import("@/view/shop/system/page/gird"),
            meta: {
                title: "首页宫格"
            }
        },


        {
            path: "sys-notice",
            name: "shopSystemSysNotice",
            component: () => import("@/view/shop/system/notify/sysNotice/list"),
            meta: {
                title: "系统公告"
            }
        },
        {
            path: "sys-notice-edit",
            name: "shopSystemSysNoticeEdit",
            component: () => import("@/view/shop/system/notify/sysNotice/edit"),
            meta: {
                title: "编辑系统公告"
            }
        },
        {
            path: "wx-official",
            name: "shopSystemWxOfficial",
            component: () => import("@/view/shop/system/wxOfficial"),
            meta: {
                title: "微公众号"
            }
        },
        {
            path: "wx-official-substitute",
            name: "shopSystemWxOfficial",
            component: () => import("@/view/shop/system/wxOfficialSubstitute"),
            meta: {
                title: "借权发放佣金配置"
            }
        },
        {
            path: "wxapp",
            name: "shopSystemWxapp",
            component: () => import("@/view/shop/system/wxapp"),
            meta: {
                title: "微小程序"
            }
        },
        {
            path: "joinpay",
            name: "shopSystemJoinpay",
            component: () => import("@/view/shop/system/joinpay"),
            meta: {
                title: "汇聚支付"
            }
        },
        {
            path: "wx-pay",
            name: "shopSystemWxPay",
            component: () => import("@/view/shop/system/wxPay"),
            meta: {
                title: "微信支付"
            }
        },
        {
            path: "ali-pay",
            name: "shopSystemAliPay",
            component: () => import("@/view/shop/system/aliPay"),
            meta: {
                title: "阿里支付"
            }
        },
        {
            path: "sns",
            name: "shopSystemSns",
            component: () => import("@/view/shop/system/sns"),
            meta: {
                title: "短信配置"
            }
        },
        {
            path: "attach",
            name: "shop-system-attach",
            component: () => import("@/view/shop/system/attach"),
            meta: {
                title: "远程附件"
            }
        },
        {
            path: "ad-popup",
            name: "system-ad-popup",
            component: () => import("@/view/shop/system/adPopup"),
            meta: {
                title: "弹窗广告"
            }
        },
    ]
}