import Vue from "vue";
import config from "@/config";

const pointsMall = {};
const base = config.api_base + "/shop/plugin/points-mall";

pointsMall.shopGoodsGift = (params) => Vue.axios.post(base + "/shop-goods-gift", params);
pointsMall.shopGoodsGiftEdit = (params) => Vue.axios.post(base + "/shop-goods-gift-edit", params);

pointsMall.conf = (params) => Vue.axios.post(base + "/conf", params);
pointsMall.confEdit = (params) => Vue.axios.post(base + "/conf-edit", params);

pointsMall.goodsSearch = (params) => Vue.axios.post(base + "/goods-search", params);
pointsMall.goodsDetail = (params) => Vue.axios.post(base + "/goods-detail", params);
pointsMall.goodsEdit = (params) => Vue.axios.post(base + "/goods-edit", params);
pointsMall.goodsDel = (params) => Vue.axios.post(base + "/goods-del", params);

pointsMall.orderSearch = (params) => Vue.axios.post(base + "/order-search", params);
pointsMall.orderHandsPay = (params) => Vue.axios.post(base + "/order-hands-pay", params);
pointsMall.orderSend = (params) => Vue.axios.post(base + "/order-send", params);
pointsMall.orderHandsFinished = (params) => Vue.axios.post(base + "/order-hands-finished", params);

pointsMall.userSearch = (params) => Vue.axios.post(base + "/user-search", params);
pointsMall.userPointsDesc = (params) => Vue.axios.post(base + "/user-points-desc", params);
pointsMall.userPointsLog = (params) => Vue.axios.post(base + "/user-points-log", params);

export default pointsMall;