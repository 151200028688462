import Vue from "vue";
import config from "@/config";

const shareholder = {};
const base = config.api_base + "/shop/plugin/shareholder";

shareholder.rule = (params) => Vue.axios.post(base + "/rule", params);
shareholder.ruleEdit = (params) => Vue.axios.post(base + "/rule-edit", params);

shareholder.poolSearch = (params) => Vue.axios.post(base + "/pool-search", params);
shareholder.poolCloseLog = (params) => Vue.axios.post(base + "/pool-close-log", params);

shareholder.userSearch = (params) => Vue.axios.post(base + "/user-search", params);
shareholder.userSetShareholder = (params) => Vue.axios.post(base + "/user-set-shareholder", params);

export default shareholder;