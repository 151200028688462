export default {
    path: "team-manage",
    component: () => import("@/view/shop/plugin/team-manage/index"),
    redirect: "/shop/plugin/team-manage/manage",
    name: "shopPluginTeamManage",
    meta: {
        title: "团队管理"
    },
    children: [
        {
            path: "conf",
            name: "shopPluginTeamManageConf",
            component: () => import("@/view/shop/plugin/team-manage/conf"),
            meta: {
                title: "基础配置"
            }
        },
        {
            path: "level",
            name: "shopPluginTeamManageLevel",
            component: () => import("@/view/shop/plugin/team-manage/level"),
            meta: {
                title: "等级设置"
            }
        },
        {
            path: "manage",
            name: "shopPluginTeamManageManage",
            component: () => import("@/view/shop/plugin/team-manage/manage"),
            meta: {
                title: "用户管理"
            }
        }
    ]
}